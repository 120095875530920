import React, { useState, useEffect } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import './style.css'
import '../Calculators/style.css'
import icon_1 from '../../img/location.png'
import icon_2 from '../../img/speedometer.png'
import icon_3 from '../../img/petrol.png'
import calendar from '../../img/calendar.png'
import privod from '../../img/privod.png'
import locations from '../../img/locations.png'
import contacts from '../../img/contacts.svg'
import colors from '../../img/colors.png'
import more from '../../img/more.svg'
import kpp from '../../img/kpp.png'
import infomore from '../../img/infomore.svg'
import ModalContactUs from '../ModalContactUs/ModalContactUs'
import { useParams } from 'react-router-dom'
import { CarType } from '../../types/appTypes'
import { useTranslation } from 'react-i18next'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageGallery from 'react-image-gallery'

type Props = {
  data: CarType[]
}

const CarDetails = ({ data = [] }: Props) => {
  const { t } = useTranslation()
  const [item, setItem] = useState<CarType>({} as CarType)

  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    const found = data.find((item) => Number(item.id) === Number(id))
    if (found) {
      setItem(found)
    }
  }, [data, id])

  const [open, setOpenModal] = useState(false)
  const openModal = () => {
    setOpenModal(true)
  }
  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Container fluid className="pb-5 mb-5">
        <div className="p-3">
          <h5 className="blue-line"></h5>
          <h5 className="header-details">{t('details')}</h5>
          <h6 className="mt-3">{item.name}</h6>
        </div>

        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <ImageGallery
              items={
                item.images?.map((image) => {
                  return {
                    original: image.url,
                    thumbnail: image.url,
                  }
                }) ?? []
              }
            />
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <h4 style={{ fontWeight: 'bold' }} className="pl-2">
              {item.name}
            </h4>
            <small style={{ fontWeight: 'bold' }} className="pl-3 mb-4">
              {/* {t('partners')} */}
              {t('details')}
            </small>

            <Row className="align-center mt-3">
              <Col className="center-items">
                <img src={icon_2} className="icon-small" alt="odometer" />
                {item.odometer} {t('th_nd')}. км
              </Col>
              <Col className="center-items">
                <img src={icon_1} className="icon-small" alt="city" />
                {item?.city}
                {/* {t('kyiv')} */}
              </Col>
            </Row>
            <Row className="align-center mb-3">
              <Col className="center-items">
                <img src={icon_3} className="icon-small" alt="fuel" />
                {item.fuel}
              </Col>

              <Col className="center-items">
                <img src={kpp} className="icon-small" alt="type drive" />
                {item.drive}
              </Col>
            </Row>

            <Row className="mt-4 row-modal">
              <Col lg={12} md={12} sm={12} xs={12} className="font-weight-bold">
                <img src={calendar} alt="calendar" width="15" className="mr-2" />{' '}
                {t('yearProd')}:
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                {' '}
                {item.year}
              </Col>
            </Row>
            <Row className="mt-1 row-modal">
              <Col lg={12} md={12} sm={12} xs={12} className="font-weight-bold">
                <img src={privod} alt="calendar" width="15" className="mr-2" />{' '}
                {t('privod')}:
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                {item.transmission}
              </Col>
            </Row>
            <Row className="mt-1 row-modal">
              <Col lg={12} md={12} sm={12} xs={12} className="font-weight-bold">
                <img src={colors} alt="calendar" width="15" className="mr-2" />{' '}
                {t('color')}:
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                {' '}
                {item.color}
              </Col>
            </Row>
            {/* <Row>
              <Col className="mt-3 mb-3 row-modal">
                <button className="btn-item buy-now" onClick={openDetails}>
                  {t('moreDetails')}
                </button>
              </Col>
            </Row> */}
            {/* {showDetails ? ( */}
            <>
              <Row className="mt-1 row-modal">
                <Col lg={12} md={12} sm={12} xs={12} className="font-weight-bold">
                  <img src={more} alt="calendar" width="15" className="mr-2" />{' '}
                  {t('description')}:
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {item.description}
                </Col>
              </Row>
              <Row className="mt-1 row-modal">
                <Col lg={12} md={12} sm={12} xs={12} className="font-weight-bold">
                  <img src={infomore} alt="calendar" width="15" className="mr-2" />{' '}
                  {t('extraInfo')}:
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {' '}
                  {item.extraInfo}
                </Col>
              </Row>
              <Row className="mt-1 row-modal">
                <Col lg={12} md={12} sm={12} xs={12} className="font-weight-bold">
                  <img src={contacts} alt="calendar" width="15" className="mr-2" />{' '}
                  {t('contacts')}:
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <ul className="list-unstyled">
                    <li>+38 050 249 09 09</li>
                    <li> +38 093 249 09 09</li>
                    <li> +38 097 249 09 09</li>
                  </ul>
                </Col>
              </Row>
              <Row className="mt-1 row-modal">
                <Col lg={12} md={12} sm={12} xs={12} className="font-weight-bold">
                  <img src={locations} alt="calendar" width="15" className="mr-2" />{' '}
                  {t('whereLocation')}
                </Col>
                <Col lg={12} md={12} sm={12} xs={12}>
                  {t('address')}
                </Col>
              </Row>
            </>
            {/* ) : null} */}
            <Row className="align-center mt-4">
              <Col>
                <small className="price-details">$ {item.price}</small>
              </Col>
              <Col>
                <button className="btn-item buy-now" onClick={openModal}>
                  {t('buyOut')}
                </button>
              </Col>
            </Row>

            <ModalContactUs open={open} onHide={closeModal} closeModal={closeModal} />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CarDetails
