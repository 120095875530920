import React from 'react'
import { Container } from 'react-bootstrap'
import './style.css'
import SignUpForm from '../SignUpForm/SignUpForm'

const Profile = () => {
  return (
    <Container fluid className="p-5">
      <SignUpForm />
    </Container>
  )
}

export default Profile
