import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

const translationUa = {
  head: 'Досить мріяти про Авто',
  subhead: 'для тебе завжди є пропозиції',
  search: 'Пошук...',
  why: 'Чому',
  reason1: 'Кредит та Лiзинг',
  reason1More: 'Варіанти розрахунку: Кредит, лізинг, гроші під заставу авто, TRADE-IN.',
  reason2: 'Зручний спосіб оплати',
  reason2More: 'Готівковий та безготівковий розрахунок з НДС та без нього.',
  reason3: 'Якicть',
  reason3More: 'Кожен покупець гарантовано отримає інформацію про реальний стан авто.',
  reason4: 'Вигода',
  reason4More: 'Економія часу та коштів на підбір авто.',
  reason5: 'Європейська практика',
  reason5More: 'Покупка авто в онлайн режимі з гарантією якості.',
  reason6: 'Досвiд',
  reason6More: 'Понад 20 років в  автобізнесі.',
  org: 'Організація',
  owner: 'Засновник',
  location: 'Місцеположення',
  ownerHead: 'Президент Всеукраїнської асоціації автобізнесменів',
  ownerName: 'Володимир Кузьменко',
  about:
    "Автобізнес - це ціла галузь економіки України, яка справно платить податки і рухає багато інших галузей вітчизняної промисловості. Але в той же час ця галузь системно стикається з низкою серйозних проблем. На жаль, починаючи з 90-х років минулого століття, український вторинний автомобільний ринок переживає не кращі часи. Автомобільний бізнес - це лакмусовий папірець для кримінального світу. На жаль, є багато шахраїв, системно намагаються провернути різні непорядні афери, ущемляючи права автомобілістів. Людей, які намагаються чесно купити або продати машину. Саме тому ми з вами об'єднуємося і впевнено рухаємося до принципово новим стандартам роботи цього ринку.",
  orgHead: 'Громадська Організація',
  orgName: 'Всеукраїнська асоціація автобізнесменів',
  orgMore:
    'Наші ключові цілі - порядок, законність і надійність ведення автобізнесу в Україні. Ми відстоюємо порушені права покупців і продавців, забезпечуємо прозорі продажу автомобілів, піклуємося про розширення необхідної для автомобілістів інфраструктури. Кожен може стати членом нашої організації і допомагати нам робити автомобільний ринок України по-справжньому європейським!',
  address: 'м.Київ, проспект Степана Бандери, 13',
  we: 'Ми',
  smi: 'У ЗМІ',
  contacts: 'Контакти',
  adressHead: 'Адреса',
  social: 'Ми в соціальних мережах:',
  mob: 'Бери участь у нашому аукціоні. Завнатажуй додаток',
  choose: 'Обери',
  car: 'Авто Своєї Мрії',
  year: 'Рік',
  price: 'Ціна',
  last: 'Останні',
  updates: 'Оновлення',
  noCars: 'Авто відсутні',
  main: 'Головна',
  auction: 'Аукціон',
  willBuy: 'Купимо',
  willBuyCar: 'Купимо Ваше Авто',
  ukrReg: 'української реєстрації',
  callUs: 'Телефонуйте за номером',
  sendEmail: 'Або відправляйте email за адресою',
  request: 'Залиште заявку',
  name: "Ім'я",
  message: 'Повідомлення',
  errorForm: 'Поле повинно бути заповненим.',
  format: 'Невірний формат',
  send: 'Відправити',
  noReq: 'Поки що запитів немає',
  more: 'Більше',
  details: 'Деталі авто',
  yearProd: 'Рік випуску',
  privod: 'Привід',
  color: 'Колір',
  description: 'Опис',
  extraInfo: 'Додаткова інформація',
  whereLocation: 'Де ми знаходимось ?',
  buyOut: 'Придбати',
  contactWithUs: "Зв'яжiться з нами",
  detailsByPhone: 'Всі деталі за номерами:',
  cancel: 'Скасувати',
  activeStatus: 'активний',
  passiveStatus: 'неактивний',
  startBid: 'Початкова ставка',
  buyOutPrice: 'Ціна викупу',
  id: 'ID аукціону',
  dataStart: 'Початок аукцiону',
  setBid: 'Поставити ставку',
  revert: 'Скинути',
  km: 'тис. км',
  kyiv: 'Київ',
  businessmen: 'Автобізнесменів',
  loading: 'Завантаження...',
  read: 'Читати',
  partners: 'Нашi партнери',
  moreDetails: 'Бiльше iнформації',
  yearFrom: 'від',
  yearTo: 'до',
  noCarsAuction: 'Аукцiони тимчасово вiдсутнi',
  chooseFilter: 'Усi',
  th_nd: 'тис',
}
const translationRu = {
  head: 'Хватит мечтать об Автомобиле',
  subhead: 'для тебя всегда есть предложения',
  search: 'Поиск...',
  why: 'Почему',
  reason1: 'Кредит и Лизинг',
  reason1More: 'Варианты расчёта: Кредит, лизинг, деньги под залог авто, TRADE-IN.',
  reason2: 'Удобный способ оплаты',
  reason2More: 'Наличный и безналичный расчёт с НДС и без него.',
  reason3: 'Качество',
  reason3More:
    'Каждый покупатель гарантированно получит информацию о реальном состоянии авто.',
  reason4: 'Выгода',
  reason4More: 'Экономия времени и средств на подбор авто.',
  reason5: 'Европейская практика',
  reason5More: 'Приобретение авто в онлайн режиме с гарантией качества.',
  reason6: 'Опыт',
  reason6More: 'Более 20 лет в автобизнесе.',
  org: 'Организация',
  owner: 'Основатель',
  location: 'Местоположение',
  ownerHead: 'Президент Всеукраинской ассоциации автобизнесменов',
  ownerName: 'Владимир Кузьменко',
  about:
    'Автобизнес - это целая отрасль экономики Украины, которая исправно платит налоги и движет многие другие отрасли отечественной промышленности. Но в то же время эта отрасль системно сталкивается с рядом серьезных проблем. К сожалению, начиная с 90-х годов прошлого века, украинский вторичный автомобильный рынок переживает не лучшие времена. Автомобильный бизнес - это лакмусовая бумажка для криминального мира. К сожалению, есть много мошенников, которые системно пытаются провернуть разные неблаговидные аферы, ущемляя права автомобилистов. Людей, которые пытаются честно купить или продать машину. Именно поэтому мы с вами объединяемся и уверенно движемся к принципиально новым стандартам работы этого рынка.',
  orgHead: 'Общественная Организация',
  orgName: 'Всеукраинская ассоциация автобизнесменов',
  orgMore:
    'Наши ключевые цели - порядок, законность и надежность ведения автобизнеса в Украине. Мы отстаиваем нарушенные права покупателей и продавцов, обеспечиваем прозрачные продажи автомобилей, заботимся о расширении необходимой для автомобилистов инфраструктуры. Каждый может стать членом нашей организации и помогать нам делать автомобильный рынок Украины по-настоящему европейским!',
  address: 'г. Киев, проспект Степана Бандеры, 13',
  we: 'Мы',
  smi: 'В СМИ',
  contacts: 'Контакты',
  adressHead: 'Адрес',
  social: 'Мы в социальных сетях: ',
  mob: 'Принимай участие в нашем аукционе. Скачивай приложение',
  choose: 'Выбери',
  car: 'Авто Своей Мечты',
  year: 'Год',
  price: 'Цена',
  last: 'Последние',
  updates: 'Обновления',
  noCars: 'Авто отсутствует',
  main: 'Главная',
  auction: 'Аукцион',
  willBuy: 'Купим',
  willBuyCar: 'Купим Ваш Автомобиль',
  ukrReg: 'украинской регистрации',
  callUs: 'Звоните по номеру',
  sendEmail: 'Или отправляйте email на почту',
  request: 'Оставьте заявку',
  name: 'Имя',
  message: 'Сообщение',
  errorForm: 'Поле не должно быть пустым.',
  format: 'Неверный формат',
  send: 'Отправить',
  noReq: 'Пока нету запросов',
  more: 'Больше',
  details: 'Подробности авто',
  yearProd: 'Год выпуска',
  privod: 'Привод',
  color: 'Цвет',
  description: 'Описание',
  extraInfo: 'Дополнительная информация',
  whereLocation: 'Где мы находимся ?',
  buyOut: 'Купить',
  contactWithUs: 'Свяжитесь с нами',
  detailsByPhone: 'Все подробности по номерам:',
  cancel: 'Отмена',
  activeStatus: 'активный',
  passiveStatus: 'неактивный',
  startBid: 'Начальная ставка',
  buyOutPrice: 'Цена викупа',
  id: 'ID аукциона',
  dataStart: 'Начало аукциона',
  setBid: 'Поставить ставку',
  revert: 'Сбросить',
  km: 'тыс. км',
  kyiv: 'Киев',
  businessmen: 'Автобизнесменов',
  loading: 'Загрузка...',
  read: 'Читать',
  partners: 'Наши партнери',
  moreDetails: 'Больше информации',
  yearFrom: 'от',
  yearTo: 'до',
  noCarsAuction: 'Аукционы временно недоступны',
  chooseFilter: 'Все',
  th_nd: 'тыс',
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      ua: {
        translation: translationUa,
      },
      ru: {
        translation: translationRu,
      },
    },
    lng: 'ua',
    fallbackLng: 'ua',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
